
const CryptoJS = require("crypto-js");
export const qCrypt =  (message) => {
    return CryptoJS.AES.encrypt(message, 'ediqiaCryptoByQe').toString();
}
// Decrypt
export const qDecrypted = (msgCrypto) => {
    const bytes  = CryptoJS.AES.decrypt(msgCrypto, 'ediqiaCryptoByQe');
    return bytes.toString(CryptoJS.enc.Utf8);
}


export const qCryptJson =  (message) => {
    return CryptoJS.AES.encrypt(JSON.stringify(message), 'ediqiaCryptoByQe').toString();
}

export const qDecryptedJson = (msgCrypto) => {
    const decrypted  = CryptoJS.AES.decrypt(msgCrypto, 'ediqiaCryptoByQe');
     const bytes = CryptoJS.enc.Utf8.stringify(decrypted)
     return JSON.parse(bytes)
}