export default {
	namespaced: true,
	state: {
		//
		statuts: [],
	},
	getters: {},
	mutations: {
		/***
		 * @Parametre
		 */

		 PERMISSIONS(state, data) {
			state.statuts = data;
		},
    },
	actions: {},
};
