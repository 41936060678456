export default {
	namespaced: true,
	state: {
		//
		dataParametre: [],
		dataParametre__info: [],
		dataCategories: [],
		dataArticles: [],
	},
	getters: {},
	mutations: {
		/***
		 * @Parametre
		 */

		 LIST_PARAMETRES_DATA(state, data) {
			state.dataParametre = data;
		},

		INFO_PARAMETRES_DATA(state, data) {
			state.dataParametre__info = data;
		},

		/***
		 * @Categorie
		 */

		 LIST_CATEGORIES_DATA(state, data) {
			state.dataCategories = data;
		},

		/***
		 * @Article
		 */

		 LIST_ARTICLES_DATA(state, data) {
			state.dataArticles = data;
		},
	},
	actions: {},
};
