export const qFrench = () => {
    return {


        // Sidebar
        sidebar: {
            dashboard: 'Tableau de bord',
            billing: {
                header: 'Facturation',
                invoice: 'Facture',
                quotes: 'Devis',
                relance: 'Relancer',
                taxe: 'Taxe',
            },
            stock_management: {
                header: 'Gestion de stock',
                my_stocks: 'Mes stocks',
                my_sales: 'Mes ventes',
                my_supply: 'Mes approvisionnements',
                supplyThreshold: 'Seuil d\'approvisionnement',
                inventaire: 'Inventaire',               
            } ,
            Treasury: {
                header: 'Trésorerie',

                //
                expense: 'Dépenses',
                simple_expense: 'Dépenses simples',
                type_expense: 'Type de dépenses',

                transaction: 'Transactions',
                accounts: 'Comptes',
                loan: 'Emprunts',
            },
            catalog: {
                header: 'Catalogue',
                catalog: 'Mon catalogue',
                category: 'Catégories',
                article: 'Articles',
            },
            crm: {
                header: 'CRM',
                client: 'Client',
                provider: 'Fournisseur',
                prospecting: 'Prospecting',
                prospect: 'Prospect',
            },
            company: {
                header: 'Company',
                presonal: 'Personal',
                project: 'Project',
            },
            setting: {
                header: 'Paramètres',
                permissions: 'Permissions',
            },
        },


        login: {
            header: 'Bienvenue sur Ediqia',
            subHeader: "Se connecter pour commencer l'aventure",

            rheader: 'L\'aventure commence ici',
            rsubHeader: 'Accroissez vos performances',

            eheader: 'Allons y',
            esubHeader: "Créer votre organisation",
            input: {
                firstName: 'Nom',
                lastName: 'Prénoms',
                username: 'Nom d\'utilisateur',
                companyID: 'organization\'s identifier',
                email: 'Adresse email',
                password: 'Mot de passe',
                forq: 'Mot de passe oublié?',
                location: 'Localisation',
                contact: 'Contact',
                acceptContract: 'En continuant, vous acceptez',
                NameOrganization: 'Nom de l\'organisation',
                FieldOfActivity: 'Domaine d\'activité',
                Size: 'Taille',
                StaffMembers: 'Membres du personnel',
                SiteWeb: 'Site web',
                Currency: 'Devise',
                FixedPhone: 'Téléphone fixe',
                file: 'Logo de votre organisation',
                phPassword: 'Mot de passe',
                phFieldOfActivity: 'Veillez selectionner votre domaine d\'activité',
                phSize: 'Veillez selectionner la taille de votre entreprise',
                phCurrency: 'Veillez selectionner la devise de votre pays',
                phLocation: 'Entrez votre localisation',
                phFile: 'Choisir le logo de votre organisation',
            },
            generalTerms: 'Terme general d\'utilisation',
            privacyPolicy: 'Politique de confidentialité',
            generalTermsSales: 'Terme general de vente',
            btn_login: 'Log in',
            remenber: 'Se souvenir de moi',
            btn_login: 'Se connecter',
            is_login: 'Vous avez déjà un compte?',
            btn_create: 'Créer un compte',
            register: 'S\'inscrire',
            createYourOrganization: 'Créer votre organisation',
        
        },



        connexion: {
            logout: 'Déconnexion',
            profil: 'Profil',
            'my-account': 'Mon compte',
            'my-company': 'Mon entreprise',
            qRole__g: 'Gestionnaire',
            qRole__e: 'Employé',
            upgrade: 'Mise à niveau',
        },



        navbar: {
            fast_action: 'Action rapide',
            createInvoice: 'Créer une facture',
            createQuote: 'Créer un devis',
            createLoan: 'Créer un emprunt',
            createSupply: 'Créer un approvisionnement',
            createInventory: 'Créer un inventaire',
            createExpense: 'Créer une dépense',
            createSimpleExpense: 'Créer une dépense simple',
            createTypeExpense: 'Créer un type de dépense',
            createAccount: 'Créer un compte',
            createCategory: 'Créer une catégorie',
            createArticle: 'Créer un article',
            createClient: 'Créer un client',
            createProvider: 'Créer un fournisseur',
            createProspect: 'Créer un prospect',
            createProspecting: 'Créer un prospecting',
            createProject: 'Créer un projet',
            createPersonal: 'Créer un personnel',
        },


        dashboard: {
            header: 'Tableau de bord',
            annualTurnover : 'Chiffre d\'affaires annuel',
            lastMonth : 'Mois précédent',
            currentMonth : 'mois en cours',
            turnover: 'Chiffre d\'affaires',
            expenses : 'Dépenses',
            income: 'Revenus',


            //Devis à relancer 
            quotes: 'Devis à relancer',
            amount: 'Montant',
            nombre: 'Nombre',
            deadlines: 'Les dates limites',
            etat: 'État',
            voir: 'voir',
            Impayés: 'Impayés',
            paye: 'Payé',
            topaid: 'À payer',
            Partiellement_payé: 'Partiel',


            //Vos dernières factures
            your_last_invoice: 'Vos dernières factures',
        },

        tableColumns: {
            first_name: 'Prénom',
            last_name: 'Nom',
            email: 'Email',
            phone: 'Téléphone',
            address: 'Adresse',
            city: 'Ville',
            country: 'Pays',
            action: 'Action',
            amount: 'Montant',
            date: 'Date',
            state: 'État',
            status: 'Statut',
            details: 'Détails',

        },


        
        account: {
            header: 'Mon compte',
            sub: ' Gestion de vos comptes',
            Balance: 'Solde',
            seeMore: 'Voir plus',
            number: ' Number '
        },

        


        // Statistic
        statistic_Date: {
            mois_en_cours: 'Mois en cours',
            mois_precedent: 'Mois précédent',
            annee_en_cours: 'Année en cours',
            annee_precedente: 'Année précédente',
            debut_la_creation: 'Début de la création',
            fin_la_creation: 'Fin de la création',
            this_year: 'Cette année',

        },

        statistic: {
            header: 'Statistiques',
            sub: '',
            best_customers: 'Meilleurs clients',
            best_sold: 'Meilleurs ventes',
           
        },

        statistic_chart: {
            amount_sold: 'Montant des ventes',
            quantity: 'Quantité',
        }
    }
}