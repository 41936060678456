import axios from "axios";

const { default: URL } = require("@/views/pages/request");
const { qCrypt, qCryptJson } = require("./qeCrypt");

class qcEntreprise {
    constructor(){

    }

    async info() {
        
          const { data } = await axios.get(URL.ENTREPRISE_INFO);
    
          if (data) {
             
            // Devis
            const cryptData = qCrypt(data[0].devise[0].libelle)
            // const cryptData__pass = qCrypt(data[0].nombre_jour_restant)
            localStorage.setItem('entreprise_info', cryptData)
            localStorage.setItem('qhze_022', qCryptJson(data[0]))
          }
        
      }
}

export default new qcEntreprise()