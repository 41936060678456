import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue';
export default (root, variant, position, text) =>{
    root.$toast(
        {
            component: ToastificationContentVue,
            props: {
                title: 'Mise à jour',
                icon: 'InfoIcon',
                text: 'Mise à jour, effectuez avec succès !',
                variant: 'info',
            },
        },
        {
            position,
        }
    );
}

export const toast_upgrade = (root, variant, position, text) =>{
    root.$toast(
        {
            component: ToastificationContentVue,
            props: {
                title: 'Succès',
                icon: 'UploadIcon',
                text: "Passez à la version premiun...",
                variant: 'warning',
            },
        },
        {
            position,
        }
    );
}


export const toast_sucess = (root, variant, position, text) =>{
    root.$toast(
        {
            component: ToastificationContentVue,
            props: {
                title: 'Succès',
                icon: 'CheckIcon',
                text: "Opération effectuez avec succès ! ",
                variant: 'success',
            },
        },
        {
            position,
        }
    );
}


export const toast_reception = (root, variant, position, text) =>{
    root.$toast(
        {
            component: ToastificationContentVue,
            props: {
                title: 'Réception des données',
                icon: 'UserIcon',
                text: "Votre compte a été créé avec succès !",
                variant: 'success',
            },
        },
        {
            position,
        }
    );
}


export const toast_feedback = (root, variant, position, text) =>{
    root.$toast(
        {
            component: ToastificationContentVue,
            props: {
                title: 'Rapport Envoyé',
                icon: 'MailIcon',
                text: "Merci de votre contrution a l'améloration d'ediqia !",
                variant: 'primary',
            },
        },
        {
            position,
        }
    );
}


export const toast_orign = (root, title, icon, text, variant, position) =>{
    root.$toast(
        {
            component: ToastificationContentVue,
            props: {
                title ,
                icon,
                text,
                variant,
            },
        },
        {
            position,
        }
    );
}


export const toast_send_invoice = (root, variant, position, text) =>{
    root.$toast(
        {
            component: ToastificationContentVue,
            props: {
                title: 'Facture envoyé',
                icon: 'MailIcon',
                text: "Votre facture a été envoyé avec succès ! ",
                variant: 'primary',
            },
        },
        {
            position,
        }
    );
}



export const toast_destory = (root, variant, position, text) =>{
    root.$toast(
        {
            component: ToastificationContentVue,
            props: {
                title: 'Supprimé',
                icon: 'TrashIcon',
                text: "Suppression effectuez avec succès ! ",
                variant : 'success',
            },
        },
        {
            position,
        }
    );
}


export const toast_update = (root, variant, position, text) =>{
    root.$toast(
        {
            component: ToastificationContentVue,
            props: {
                title: 'Modification',
                icon: 'Edit3Icon',
                text: "Modification effectuez avec succès !",
                variant : 'success',
            },
        },
        {
            position,
        }
    );
}

export const toast_error = (root, variant, position, text) =>{
    root.$toast(
        {
            component: ToastificationContentVue,
            props: {
                title: 'Erreur au serveur',
                icon: 'AlertTriangleIcon',
                text: "Oups, une error s'est produite au serveur !",
                variant : 'danger',
            },
        },
        {
            position,
        }
    );
}


export const toast_loading = (root, delay) =>{
    root.$bvToast.toast(`Loading...`, {
        title: 'En cours d\'excution',
        autoHideDelay: delay,
        solid: true,
        appendToast: true,
        variant: 'primary'
      })
}

