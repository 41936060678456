export default {
	namespaced: true,
	state: {
		//
		qTranslateChange: window.navigator.languages[0] || 'en',
	},
	getters: {},
	mutations: {
		/***
         * @todo translate app in english or in french
		 * @param [state, data]
		 */
		TRANSLATE_APP(state, data) {
			state.qTranslateChange = data;
		},

	},
	actions: {},
};
